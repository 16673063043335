<template>
  <div class="launchpad">
    <el-carousel
      :height="isMobile ? '438px' : '338px'"
      :arrow="idounderwayList.length > 1 ? 'always' : 'never'"
      v-if="idounderwayList.length > 0"
      :autoplay="true"
    >
      <el-carousel-item v-for="(item, index) in idounderwayList" :key="index">
        <div
          class="banner-background cur"
          :style="
            'background-image: linear-gradient(90deg, rgba(0,0,0,.6),rgba(0,0,0,.6)),url(' +
            item.banner +
            ');'
          "
          @click="entrance(item.id)"
        >
          <pouring :boxtitle="false" :pouringdat="item"></pouring>
          <div class="banner-right">
            <img
              class="banner-logo"
              :src="item.logo ? item.logo : '../../static/images/18.png'"
              alt=""
              srcset=""
              @error="imageLoad(item.logo, index)"
            />
            <p class="cikent">{{ item.name }}</p>
            <p class="mony-noe">
              Fundraise Goal:
              <span
                >{{
                  item.fundraiseGoalInput
                    ? $tool.formatMonenyNumber(item.fundraiseGoalInput)
                    : 0
                }}
                {{ item.inputToken }}</span
              >
            </p>
            <p class="subheading" style="margin-top: 14px">
              Initial valuation:
              {{
                item.initialValuation
                  ? $tool.formatNumber(item.initialValuation)
                  : 0
              }}
            </p>
            <p class="subheading">
              Initial token price: ${{ item.initialTokenPrice }}
            </p>
            <p class="subheading">
              Base allocation: {{ item.baseAllocation + ' ' +  item.outputToken}}
            </p>
            <div class="progress">
              <p class="top">
                {{
                  item.fundraiseProgress
                    ? $tool.formatMonenyNumber(item.fundraiseProgress)
                    : 0
                }}
                /
                {{
                  item.fundraiseGoal
                    ? $tool.formatMonenyNumber(item.fundraiseGoal)
                    : 0
                }}
                {{ item.outputToken }}
              </p>
              <el-progress
                :text-inside="true"
                :percentage="
                  proportions(item.fundraiseProgress, item.fundraiseGoal)
                "
              ></el-progress>
              <p class="bup">
                {{
                  item.fundraiseGoalInput
                    ? $tool.formatMonenyNumber(item.fundraiseGoalInput)
                    : 0
                }}
                {{ item.inputToken }}
              </p>
            </div>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>

    <div class="launchpad-list" v-if="idounderwayList.length > 0">
      <p class="title">
        <span class="icon"> <svg-icon iconClass="underway"></svg-icon> </span>
        <span class="text">Underway IDOs</span>
        <span class="num">({{ idounderwayList.length }})</span>
      </p>
      <ul class="list">
        <li
          @click="entrance(itms.id)"
          class="cur"
          v-for="(itms, index) in idounderwayList"
          :key="index"
        >
          <img :src="itms.logo" alt="" srcset="" />
          <div class="right">
            <p class="MANUFACTORY">
              {{ itms.name }}
            </p>
            <p class="amounts">
              Fundraise Goal: {{ $tool.formatNumber(itms.fundraiseGoal) }}
            </p>
            <div class="times">
              <div class="timer-block">
                <span>{{ btnDay(itms.sellEndTime, "day", 1) }}</span>
                <span> {{ btnDay(itms.sellEndTime, "day", 2) }}</span>
              </div>
              <div class="unit">:</div>
              <div class="timer-block">
                <span>{{ btnDay(itms.sellEndTime, "hour", 1) }}</span>
                <span> {{ btnDay(itms.sellEndTime, "hour", 2) }}</span>
              </div>
              <div class="unit">:</div>
              <div class="timer-block">
                <span>{{ btnDay(itms.sellEndTime, "min", 1) }}</span>
                <span> {{ btnDay(itms.sellEndTime, "min", 2) }}</span>
              </div>
              <div class="unit">:</div>
              <div class="timer-block">
                <span>{{ btnDay(itms.sellEndTime, "second", 1) }}</span>
                <span>
                  {{ btnDay(itms.sellEndTime, "second", 2) }}
                </span>
              </div>
            </div>
            <div class="progress">
              <el-progress
                :percentage="
                  proportions(itms.fundraiseProgress, itms.fundraiseGoal)
                "
              ></el-progress>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="launchpad-list" v-if="idoupcomingList.length > 0">
      <p class="title">
        <span class="icon"> <svg-icon iconClass="nextIDO"></svg-icon> </span>
        <span class="text">Next IDOs</span>
        <span class="num">({{ idoupcomingList.length }})</span>
      </p>
      <ul class="list">
        <li
          @click="entrance(itms.id)"
          class="cur"
          v-for="(itms, index) in idoupcomingList"
          :key="index"
        >
          <img :src="itms.logo" alt="" srcset="" />
          <div class="right">
            <p class="MANUFACTORY">
              {{ itms.name }}
            </p>
            <p class="amounts">
              Fundraise Goal: {{ $tool.formatNumber(itms.fundraiseGoal) }}
            </p>
            <div class="times">
              <div class="timer-block">
                <span>{{ btnDay(itms.sellStartTime, "day", 1, true) }}</span>
                <span> {{ btnDay(itms.sellStartTime, "day", 2, true) }}</span>
              </div>
              <div class="unit">:</div>
              <div class="timer-block">
                <span>{{ btnDay(itms.sellStartTime, "hour", 1, true) }}</span>
                <span> {{ btnDay(itms.sellStartTime, "hour", 2, true) }}</span>
              </div>
              <div class="unit">:</div>
              <div class="timer-block">
                <span>{{ btnDay(itms.sellStartTime, "min", 1, true) }}</span>
                <span> {{ btnDay(itms.sellStartTime, "min", 2, true) }}</span>
              </div>
              <div class="unit">:</div>
              <div class="timer-block">
                <span>{{ btnDay(itms.sellStartTime, "second", 1, true) }}</span>
                <span>
                  {{ btnDay(itms.sellStartTime, "second", 2, true) }}
                </span>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <!-- :description="$i18n.locale == 'en' ? 'No Data' : '暂无数据'" -->
    <el-empty :image-size="200" v-if="flag" description=" ">
      <template #image>
        <svg-icon iconClass="empty" class="empty-icon"></svg-icon>
      </template>
    </el-empty>
  </div>
</template>

<script>
import pouring from "@/components/pouring.vue";
// 进行中的IDO项目 , 即将到来的IDO项目
import { idounderway, idoupcoming } from "@/api/controller";
import { mapGetters } from "vuex";

export default {
  components: { pouring },
  data() {
    return {
      idounderwayList: [],
      idoupcomingList: [],
      flag: false,
    };
  },
  created() {
    this.requires();
  },
  computed: {
    ...mapGetters(["isMobile"]),
    btnDay() {
      return (curStartTime, type, index, isUnderway = false) => {
        let date = new Date();
        let now = date.getTime();
        // 设置截止时间
        let endDate = new Date(curStartTime);
        let end = endDate.getTime();
        // 时间差
        let leftTime = isUnderway ? now - end : end - now;
        if (leftTime < 0) {
          return "0";
        }

        let day = Math.floor(leftTime / 1000 / 60 / 60 / 24);
        // 时
        let h = Math.floor((leftTime / 1000 / 60 / 60) % 24);
        // 分
        let m = Math.floor((leftTime / 1000 / 60) % 60);
        // 秒
        let s = Math.floor((leftTime / 1000) % 60);
        const dayMap = {
          day: day < 10 ? "0" + day : day,
          hour: h < 10 ? "0" + h : h,
          min: m < 10 ? "0" + m : m,
          second: s < 10 ? "0" + s : s,
        };
        const trans = dayMap[type];
        if (index == 1) {
          return trans > 9 ? trans.toString().substring(0, 1) : 0;
        } else if (trans > 9) {
          return trans.toString().substring(1, 2);
        } else {
          if (trans.toString().length > 1) {
            return trans.toString().substring(1, 2);
          } else {
            return trans;
          }
        }
      };
    },
  },
  methods: {
    proportions(val, newval) {
      if ((val / newval) * 100 >= 0 && (val / newval) * 100 <= 100) {
        return (val / newval) * 100;
      } else {
        return 0;
      }
    },
    async requires() {
      let latins = 1;
      {
        let res = await idounderway();
        // console.log(res.data,'进行中的IDO项目')
        if (res.code === 0) {
          if (res.data.length > 0) {
            latins += 1;
          }
          this.idounderwayList = res.data;
        }
      }
      {
        let res = await idoupcoming();
        // console.log(res.data,'即将到来的IDO项目')
        if (res.code === 0) {
          if (res.data.length > 0) {
            latins += 1;
          }
          this.idoupcomingList = res.data;
        }
      }

      if (latins == 1) {
        this.flag = true;
      }
    },
    imageLoad(src, index) {
      var Img = new Image();
      Img.src = src;
      if (Img.fileSize > 0 || (Img.width > 0 && Img.height > 0)) {
      } else {
        this.idounderwayList[index].logo = ""; //显示图片未找到 图片
      }
    },
    entrance(id) {
      this.$router.push({ path: "/newdetails", query: { id } });
    },
  },
};
</script>

<style lang="less" scoped>
.launchpad {
  .launchpad-list {
    margin-top: 45px;
    .title {
      font-size: 18px;
      color: #ffffff;
      margin-bottom: 15px;
      vertical-align: baseline;
    }
    .icon {
      font-size: 22px;
      margin-right: 8px;
      color: #1edfa6;
    }
    .num {
      vertical-align: baseline;
      color: #777c83;
    }
    .list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 20px;
      grid-row-gap: 20px;
      // media start
      @media screen and(min-width: 1365px) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media screen and(min-width: 1920px) {
        grid-template-columns: repeat(3, 1fr);
      }
      @media screen and(min-width: 2560px) {
        grid-template-columns: repeat(4, 1fr);
      }
      // media end
      li {
        list-style: none;
        width: 100%;
        height: 195px;
        background: linear-gradient(0deg, #2d3138, #3a3f45);
        border-radius: 30px;
        overflow: hidden;

        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 20px;
        grid-row-gap: 20px;

        .MANUFACTORY {
          margin: 15px 0;
          color: #fefefe;
          font-size: 18px;
        }
        img {
          width: 100%;
          height: 195px;
          grid-area: ~"1 / 1 / 2 / 3";
        }
        .right {
          grid-area: ~"1 / 3 / 2 / 6";
        }
        .times {
          margin: 20px 0;
          display: flex;
          align-items: center;

          .timer-block {
            background: #22252c;
            border-radius: 2px;
            padding: 6px;

            display: flex;
            align-content: center;
            justify-content: center;

            font-weight: bold;
            color: #7b8087;
          }
          .unit {
            padding: 0 4px;
          }
        }
        .progress {
          width: 80%;
        }
      }
    }
  }
  /deep/.el-carousel {
    // height: 338px;
    .el-carousel__arrow--left {
      background: url("../../static/images/zuo.png") 100% center/100% no-repeat;
    }
    .el-carousel__arrow--right {
      background: url("../../static/images/qieh.png") 100% center/100% no-repeat;
    }
    .el-carousel__indicator.is-active button {
      background: #1edfa6;
    }
    .el-carousel__button {
      width: 7px;
      height: 7px;
      background: #626262;
      border-radius: 50%;
    }
    .banner-background {
      height: 338px;
      border-radius: 10px;
      background-size: cover;
      background-position: right;
      .banner-right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        .progress {
          width: 272px;
          margin-top: 30px;
          .bup {
            float: right;
            font-size: 12px;
            color: #fefefe;
          }
          .top {
            font-size: 12px;
            color: #fefefe;
          }
        }
        .subheading {
          font-size: 14px;
          color: #7b8087;
          line-height: 2;
        }
        .mony-noe {
          font-size: 16px;
          margin-top: 20px;
          // font-weight: bold;
          color: #fefefe;
          span {
            color: #1edfa6;
          }
        }
        .cikent {
          font-size: 18px;
          font-weight: bold;
          color: #fefefe;
          margin-top: 15px;
        }
        .banner-logo {
          width: 66px;
          height: 65px;
          border-radius: 50%;
          border: 1px solid #ffffff;
        }
        float: right;
        width: 317px;
        height: 338px;
        background: rgba(34, 37, 44, 0.75);
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
  }

  /deep/.el-progress {
    margin: 4px 0;
    .el-progress-bar__outer {
      height: 14px !important;
      border-radius: 7px;
      background: rgba(#606877, 0.25);
    }
    .el-progress-bar__inner {
      background: #1edfa6;
      display: flex;
      justify-content: flex-start;
      .el-progress-bar__innerText {
        margin: 1px 20px 0;
      }
    }
    .el-progress__text {
      color: #21e2a9;
    }
  }
}

.isMobile .launchpad {
  .launchpad-list .list {
    grid-template-columns: repeat(1, 1fr);
  }
  .el-carousel .banner-background {
    height: 438px;
    .banner-right {
      width: 100%;
    }
    .banner-left {
      width: 100%;
      text-align: center;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
</style>
